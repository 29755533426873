<template>
  <div class="container-secret">
    <div class="wrap-secret">
      <span class="secret-form-title">Keep your message out of Slack or Teams.</span>
      <p class="secret-form-subtitle">Share your secret to get a one-time only URL <span class="emoji">🤫</span></p> 
      <div class="wrap-input" data-validate="Please enter your message">
        <textarea maxlength="280" v-model="message" class="input" name="message" placeholder="Start typing..."></textarea>
        
        <span class="focus-input"></span>
      </div>

       <div class="wrap-input" data-validate="Please enter your message">
        <!-- <input type="text" v-model="email" class="input" name="email" placeholder="Receipient email"/> -->
        
        <span class="focus-input"></span>
      </div>



      <div class="container-secretSubmit">
        <button :disabled="isLoading" v-on:click="createMessage" class="secretSubmit">
          <span v-if="!isLoading">Create Secret</span>
          <img v-if="isLoading" width="50" height="50" src="images/loading.gif" />
        </button>
      </div>
      <div v-if="isError" class="errorMessage">Something went wrong, please try again later :(</div>
      <input type="hidden" id="clipboardData" v-model="shareableLink" />
    </div>
    <div class="footer">
      <p>Disclaimer: This is an experimental application and under no circumstances should be used as a way to communicate critical information!</p>
      <p>Contact / Feedback: keepseries@pm.me</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Home",
  data() {
    return {
      email: "",
      message: "",
      isLoading: false,
      isError: false,
      shareableLink: ""
    };
  },
  methods: {
    createMessage() {
      this.isLoading = true;
      axios
        .post("https://1trhp3mn7h.execute-api.eu-central-1.amazonaws.com/Dev/secrets", { message: this.message, email: this.email })
        .then(response => {
          console.log(response);
          this.isLoading = false;
          this.isError = false;
          this.shareableLink = `https://keepsecret.cc/#/secret/${response.data.secretId}`;

          //clear textarea
          this.message = "";
          this.$fire({
            title: "Share this link",
            html: `${this.shareableLink} <div class="copiedToClipboard">(copied to clipboard)</div>`,
            type: "success",
            allowOutsideClick: false,
            width: 600
          });
        })
        .then(() => {
          //copy to clipboard
          let clipboardData = document.querySelector("#clipboardData");
          clipboardData.setAttribute("type", "text");
          clipboardData.select();
          document.execCommand("copy");
          clipboardData.setAttribute("type", "hidden");
          window.getSelection().removeAllRanges();
        })
        .catch(error => {
          this.isLoading = false;
          this.isError = true;
          console.log(error);
        });
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.emoji{
  font-style: normal;
}
body,
html {
  height: 100%;
  font-family: "Roboto", sans-serif;
  background-color: #f2f2f2;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-bottom: 5px;
  width: 100%;
  font-style: italic;
  color: #737373;
  text-align: center;
}

.errorMessage {
  margin-top: 12px;
  display: block;
  font-size: 14px;
  color: red;
}

.copiedToClipboard {
  font-style: italic;
  font-size: 15px;
}
/*---------------------------------------------*/
a {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
}

/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

p {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/
input {
  outline: none;
  border: none;
}

input[type="number"] {
  -moz-appearance: textfield;
  appearance: none;
  -webkit-appearance: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

textarea {
  outline: none;
  border: none;
  resize: none;
}

textarea:focus,
input:focus {
  border-color: transparent !important;
}

input::-webkit-input-placeholder {
  color: #bdbdd3;
}
input:-moz-placeholder {
  color: #bdbdd3;
}
input::-moz-placeholder {
  color: #bdbdd3;
}
input:-ms-input-placeholder {
  color: #bdbdd3;
}

textarea::-webkit-input-placeholder {
  color: #bdbdd3;
}
textarea:-moz-placeholder {
  color: #bdbdd3;
}
textarea::-moz-placeholder {
  color: #bdbdd3;
}
textarea:-ms-input-placeholder {
  color: #bdbdd3;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*---------------------------------------------*/
.container {
  max-width: 1200px;
}

.container-secret {
  width: 100%;
  min-height: 90vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  position: relative;
  background-color: #f2f2f2;
}

.wrap-secret {
  width: 550px;
  background: transparent;
  padding: 25px 0px 160px 0px;
}

.secret-form {
  width: 100%;
}

.secret-form-subtitle{
  font-size: 20px;
  font-style: italic;
  color:#b1b1b1;
}

.secret-form-title {
  display: block;
  font-size: 28px;
  color: #403866;
  line-height: 1.2;
  text-align: center;
  padding-bottom: 22px;
}

.wrap-input {
  width: 100%;
  background-color: #fff;
  border-radius: 31px;
  margin-bottom: 16px;
  position: relative;
  z-index: 1;
}

.input {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  border-radius: 31px;
  font-size: 18px;
  color: #8f8fa1;
  line-height: 1.2;
  margin-top: 12px;
}

input.input {
  height: 62px;
  padding: 0 35px 0 35px;
}

textarea.input {
  min-height: 169px;
  padding: 19px 35px 0 35px;
}

.focus-input {
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: #fff;
  pointer-events: none;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input:focus + .focus-input {
  width: calc(100% + 20px);
}

.container-secretSubmit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 10px;
}

.secretSubmit {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  min-width: 150px;
  height: 62px;
  background-color: transparent;
  border-radius: 31px;
  font-size: 16px;
  color: #fff;
  line-height: 1.2;
  text-transform: uppercase;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
  position: relative;
  z-index: 1;
}

.secretSubmit::before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  border-radius: 31px;
  background-color: #827ffe;
  pointer-events: none;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.secretSubmit:hover:before {
  background-color: #403866;
  width: calc(100% + 20px);
}
</style>