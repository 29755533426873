<template>
  <div class="container-secret">
    <div class="wrap-secret">
      <span class="secret-form-title">{{ title }}</span>
      <div v-if="secretExists" class="wrap-input" data-validate="Please enter your message">
        <textarea class="input" name="message" v-model="message" readonly></textarea>
        <span class="focus-input"></span>
      </div>

      <div class="container-secretSubmit">
        <button v-on:click="getHome" class="secretSubmit">
          <span>Create your own</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      message: "",
      title: "Someone shared this with you 🤔",
      secretExists: true
    };
  },
  methods: {
    getHome() {
        this.$router.push("/");
    },
    getSecrets() {
      axios
        .get(`https://1trhp3mn7h.execute-api.eu-central-1.amazonaws.com/Dev/secrets/${this.$route.params.id}`)
        .then(response => {
          console.log(response);
          this.message = response.data.Attributes.message.S;
        })
        .catch(error => {
          console.log(error.message)
          this.secretExists = false;
            this.title = "No secrets here 😲";
          });
    }
  },
  mounted() {
    this.getSecrets();
  }
};
</script>